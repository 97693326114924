import '../sass/theme.scss'

// Our modules / classes
import metalAPI from './modules/metalsAPI'
if (window.location.pathname == '/') {
	const MetalAPI = new metalAPI()
}
import run_carousel from './modules/carousel'
if (window.location.pathname == '/' || window.location.pathname == '/about/') {
	const Carousel = new run_carousel()
}
import site from './modules/site'
import search from './modules/search'
import main_Nav from './modules/navLogic'
import mobileNav from './modules/mobileNav.js'
import Resources from './modules/resources'
import Verify from './modules/verification'
import myFavorites from './modules/favorites'
import myCart from './modules/cart'
import imgModal from './modules/imageModal'
import shippingForm from './modules/shippingForm'

const favorites = new myFavorites()
const Search = new search()
const Site = new site()
const Nav = new main_Nav()

// const Carousel = new run_carousel();
const resources = new Resources()
const MobileNav = new mobileNav()
const verify = new Verify()

const cart = new myCart()
const ImageModal = new imgModal()
