import $ from 'jquery';

class mobileNav {
	//? 1. describe and create/initiate our object

	constructor() {
		this.events();
	}

	//? 2. events

	events() {
		// console.log('help please');
		let main_object = this;
		// $('.slide-link').click(function () {
		// 	event.preventDefault();
		// 	let mydata = $(this).data('links');
		// 	console.log(mydata);
		// });

		$('.slide-link').click(function (event) {
			event.preventDefault();
			console.log('clicked!!');
			let mydata = $(this).data('links');
			let html = `<ul>`;
			for (var index in mydata) {
				html += `	<li>
                <a href="${mydata[index]['link']}" >${mydata[index]['name']}</a>
            </li>`;
			}

			html += '</ul>';
			main_object.opendSecoundSlide(html);
		});

		$('.back-btn').click(function () {
			TweenMax.to('.secound-slide', 0.5, {
				opacity: 0,
				x: 500,
				ease: Power3.easeInOut,
				onComplete: function () {
					$('.secound-slide ').addClass('hide');
					TweenMax.to('.secound-slide', 0, {
						opacity: 1,
						x: 0,
						ease: Power3.easeInOut,
					});
				},
			});
		});

		$('.close-btn-nav').click(function () {
			TweenMax.to('.mobile-drawer', 0.5, {
				opacity: 0,
				x: 500,
				ease: Power3.easeInOut,
				onComplete: function () {
					$('.mobile-drawer').addClass('hide');
					TweenMax.to('.mobile-drawer', 0, {
						opacity: 1,
						x: 0,
						ease: Power3.easeInOut,
					});
				},
			});
		});

		$('.mobile-nav-trigger').click(function () {
			console.log('clickeddd');
			$('.mobile-drawer').removeClass('hide');
			TweenMax.from('.mobile-drawer', 0.5, {
				opacity: 0,
				x: 500,
				ease: Power3.easeInOut,
			});
		});
	}

	//? 3. methods (functions)

	opendSecoundSlide(html) {
		TweenMax.from('.secound-slide', 0.5, {
			opacity: 0,
			x: 500,
			ease: Power3.easeInOut,
		});
		$('.secound-slide ').removeClass('hide');
		$('#update_slide').html(html);
	}
}

export default mobileNav;
