import $ from 'jquery';

class verify {
	//? 1. describe and create/initiate our object

	constructor() {
		this.search_input = $('#ver-input');
		this.search_button = $('#checkVerification');
		this.error_msg = $('.error_msg');

		this.events();
	}

	//? 2. events

	events() {
		let myObj = this;
		this.search_button.on('click', this.verrification);
		this.search_input.on("keydown",function search(e) {
			if(e.keyCode == 13) {
				myObj.verrification()
			}
		});
	}

	//? 3. methods (functions)
	verrification = (data) => {
        let mainObject = this;
        if(mainObject.search_input.val() === "pretty"){
 
            window.location.replace("/price-guide-welcome/");
        }else{
            console
           mainObject.error_msg.removeClass( "hide" );
        }
	}


}

export default verify;
