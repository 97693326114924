import $ from 'jquery'

class metalAPI {
	//? 1. describe and create/initiate our object

	constructor() {
		this.events()
		this.getPricesofGold()
		this.getPricesofSilver()
		this.getPricesofrhodium()
		this.getPricesofplatinum()
		this.getPricesofpalladium()
	}

	//? 2. events

	events() {
		console.log('hellow world ')
		let obj = this
		TweenMax.from('#metals_api', 0.3, { y: 250, delay: 0.5, delay: 2 })

		$(window).scroll(function () {
			console.log($(window).scrollTop())
			if ($(window).scrollTop() > 850) {
				obj.showFooter()
			} else {
				obj.hideFooter()
			}
		})
	}

	//? 3. methods (functions)
	showFooter() {
		console.log('show')
		TweenMax.to('#metals_api', 0.3, { y: 0, delay: 0.5, delay: 1 })
	}
	hideFooter() {
		console.log('hide')
		TweenMax.to('#metals_api', 0.3, { y: 250, delay: 0.5, delay: 1 })
	}

	getPricesofGold() {
		axios
			.get('https://api.metals.live/v1/spot/gold')
			.then(
				function (response) {
					// console.log(response.data[1].price);
					$('.goldPrice').text(response.data[1].price)
				},
				{
					headers: {
						'Access-Control-Allow-Origin': '*',
					},
				}
			)
			.catch(function (error) {
				console.log(error)
			})
	}
	getPricesofSilver() {
		axios
			.get('https://api.metals.live/v1/spot/silver')
			.then(
				function (response) {
					// console.log(response.data[1].price);
					$('.SilverPrice').text(response.data[1].price)
				},
				{
					headers: {
						'Access-Control-Allow-Origin': '*',
					},
				}
			)
			.catch(function (error) {
				console.log(error)
			})
	}
	getPricesofpalladium() {
		axios
			.get('https://api.metals.live/v1/spot/palladium')
			.then(
				function (response) {
					// console.log(response.data[1].price);
					$('.PalladiumPrice').text(response.data[1].price)
				},
				{
					headers: {
						'Access-Control-Allow-Origin': '*',
					},
				}
			)
			.catch(function (error) {
				console.log(error)
			})
	}
	getPricesofplatinum() {
		axios
			.get('https://api.metals.live/v1/spot/platinum')
			.then(
				function (response) {
					// console.log("platinum: ", response.data);
					$('.PlatinumPrice').text(response.data[1].price)
				},
				{
					headers: {
						'Access-Control-Allow-Origin': '*',
					},
				}
			)
			.catch(function (error) {
				console.log(error)
			})
	}
	getPricesofrhodium() {
		axios
			.get('https://api.metals.live/v1/spot/rhodium')
			.then(
				function (response) {
					// console.log(response.data);
					$('.RhodiumPrice').text(response.data[1].price)
				},
				{
					headers: {
						'Access-Control-Allow-Origin': '*',
					},
				}
			)
			.catch(function (error) {
				console.log(error)
			})
	}
}

export default metalAPI
