import $ from 'jquery'

class myCart {
	//! 1. describe and create/initiate our object
	constructor() {
		this.minus_btn = $('.minus')
		this.plus_btn = $('.plus')
		this.remove_btn = $('.remove-btn')
		this.sell_btn = $('#cartBTN')
		this.clear_cart_btn = $('#clearCartBTN')
		this.contact_form = $('.contact-form')
		this.succesfull_alert = $('.succesfull-alert')
		this.sub_btn = $('.sub-btn')
		// main priceguide page
		this.add_to_cart = $('.add-to-cart')
		this.events()
		this.autofillForm()
	}

	//! 2. events
	events() {
		let obj = this

		//? - button action
		obj.minus_btn.click(function () {
			obj.minusFunction($(this))
		})
		//? + button action
		obj.plus_btn.click(function () {
			obj.plusButton($(this))
		})
		//? remove button action
		obj.remove_btn.click(function () {
			obj.removeBtn($(this))
		})
		//? clear cart action
		obj.clear_cart_btn.click(function () {
			let allRemoveButtons = document.querySelectorAll('.remove-btn')
			console.log(allRemoveButtons)
			allRemoveButtons.forEach((el) => obj.removeBtnForClear(el))
		})
		//? sell button action
		obj.sell_btn.click(function () {
			obj.gennerateFormURL()
		})

		obj.sub_btn.click(function () {
			obj.contactFormSubmit()
		})

		$(document).on('click', '.add-to-cart', function () {
			event.preventDefault()

			let partNumber = $(this).attr('data-partNumber')
			let price = $(this).attr('data-price')
			let userID = $('#search-output').data('userid')
			let btn = $(this)
			btn.prop('disabled', true)
			btn.addClass('bisabled-btn')

			axios
				.post(
					`/wp-json/price-guide/v1/cart?user_id=${userID}&part_id=${partNumber}&part_price=${price}&part_price=${price}&part_qty=1`
				)
				.then(function (response) {
					const theCartCount = document.querySelector('.cart-count')
					const theCartCountMobile =
						document.querySelector('.cart-count-mobile')
					theCartCount.innerHTML = response.data.is_data.length
					theCartCountMobile.innerHTML = response.data.is_data.length
					console.log(response)
					btn.text('Item added')
				})
				.catch(function (error) {
					console.log(error)
				})
		})
	}

	//! 3. methods (functions)

	gennerateFormURL() {
		let productString = ''
		let EstimatedPrice = 0
		$('.cartItem').each(function (i, obj) {
			let partId = $(this).data('part_id')
			let quantity = $(this).find('.count').val()
			let Price = $(this).data('part_price')
			let calcPrice = Math.ceil(Price * quantity * 100) / 100
			EstimatedPrice += calcPrice
			productString += `<tr><td>${partId}</td><td>${quantity}</td><td>${calcPrice}</td></tr>`
		})
		EstimatedPrice = Math.ceil(EstimatedPrice * 100) / 100
		productString += `<tr><td></td><td>Total:</td><td><strong>${EstimatedPrice}</strong></td></tr>`
		console.log(productString)
		window.location.href = `/price-guide/shipping-form-packing-slip/?estPrice=${EstimatedPrice}&input_1_3=${productString}`
	}

	contactFormSubmit = () => {
		let obj = this
		obj.contact_form.addClass('hide')
		obj.succesfull_alert.removeClass('hide')
	}
	autofillForm = () => {
		var getUrlParameter = function getUrlParameter(sParam) {
			var sPageURL = decodeURIComponent(window.location.search.substring(1)),
				sURLVariables = sPageURL.split('&'),
				sParameterName,
				i
			for (i = 0; i < sURLVariables.length; i++) {
				sParameterName = sURLVariables[i].split('=')
				if (sParameterName[0] === sParam) {
					return sParameterName[1] === undefined ? true : sParameterName[1]
				}
			}
		}

		var VARIABLE = getUrlParameter('input_1_3')

		if (VARIABLE != null) {
			VARIABLE = VARIABLE.replace(/\r?\n/g, '<br />')
			document.getElementById('input_1_3').value = VARIABLE
		}
		var myPrice = getUrlParameter('estPrice')

		if (myPrice != null) {
			myPrice = myPrice.replace(/\r?\n/g, '<br />')
			// document.getElementById('estPrice').value = myPrice
		}
		if (myPrice) {
			$('#myPrice').removeClass('hide')
			$('.updatePrice').text('$' + myPrice)
		}
	}

	removeBtn = (e) => {
		let obj = this
		let userId = e.data('user_id') || e.dataset.user_id
		let partId = e.data('part_id') || e.dataset.part_id
		obj.removeItem(partId)
		axios
			.post(
				`/wp-json/price-guide/v1/cart-delete?user_id=${userId}&part_id=${partId}`
			)
			.then(function (response) {
				console.log(response)
				const theCartCount = document.querySelector('.cart-count')
				const theCartCountMobile =
					document.querySelector('.cart-count-mobile')
				if (response.data.$newArray.length > 0) {
					theCartCount.innerHTML = response.data.$newArray.length
					theCartCountMobile.innerHTML = response.data.$newArray.length
				} else {
					theCartCount.innerHTML = ''
					theCartCountMobile.innerHTML = ''
					const theClearBtn = document.querySelector('#clearCartBTN')
					theClearBtn.classList.add('hide')
				}
			})
			.catch(function (error) {
				console.log(error)
			})
	}
	removeBtnForClear = (e) => {
		let obj = this
		let userId = e.dataset.user_id
		let partId = e.dataset.part_id
		obj.removeItem(partId)
		axios
			.post(
				`/wp-json/price-guide/v1/cart-delete?user_id=${userId}&clear_cart=true`
			)
			.then(function (response) {
				console.log(response)
				const theCartCount = document.querySelector('.cart-count')
				const theCartCountMobile =
					document.querySelector('.cart-count-mobile')
				theCartCount.innerHTML = ''
				theCartCountMobile.innerHTML = ''
				const theClearBtn = document.querySelector('#clearCartBTN')
				theClearBtn.classList.add('hide')
			})
			.catch(function (error) {
				console.log(error)
			})
	}

	removeItem = (id) => {
		let obj = this
		console.log(`Deleting class with .partID-${id}`)
		$('.my-list').find(`[data-deleteCode='${id}']`).remove()

		var numItems = $('.cartItem').length
		console.log(numItems)
		if (numItems < 1) {
			$('.empty-cart-alert').removeClass('hide')
			obj.sell_btn.addClass('hide')
		}
	}

	minusFunction(e) {
		let id = e.data('part_id')
		let counter = $(`input[data-part_id='${id}']`)
		// NEW
		console.log(e.data())
		let userId = e.data('user_id') || e.dataset.user_id
		let price = e.data('part_price')
		// let quantity = e.data('part_quantity')

		if (e[0].dataset.part_quantity > 1) {
			// Decrese the minus sign data
			e[0].dataset.part_quantity--
			let newQuantity = e[0].dataset.part_quantity
			// console.log(e[0].dataset.part_quantity)

			// Update the plus sign data as well
			let plusElement = document.querySelector(`.plus[data-part_id='${id}']`)
			plusElement.dataset.part_quantity--

			// Disable spans (both plus and minus)
			e.addClass('disabled')
			plusElement.classList.add('disabled')

			// Delete item from database, to then update it with the new quantity
			axios
				.post(
					`/wp-json/price-guide/v1/cart-delete?user_id=${userId}&part_id=${id}`
				)
				.then(function (response) {
					console.log(response)
					axios
						.post(
							`/wp-json/price-guide/v1/cart?user_id=${userId}&part_id=${id}&part_price=${price}&part_qty=${newQuantity}`
						)
						.then(function (response) {
							console.log(response)
							e.removeClass('disabled')
							plusElement.classList.remove('disabled')
						})
						.catch(function (error) {
							console.log(error)
						})
				})
				.catch(function (error) {
					console.log(error)
				})
			// END NEW

			// console.log(counter.val())
			counter.val(parseInt(counter.val()) - 1)
			if (counter.val() == 0) {
				counter.val(1)
			}
		}
	}

	plusButton(e) {
		console.log(e.data())
		let userId = e.data('user_id') || e.dataset.user_id
		let id = e.data('part_id')
		// console.log(id)
		// console.log(id.toString())
		let price = e.data('part_price')
		// let quantity = e.data('part_quantity')

		// Imcreate the plus sign data
		e[0].dataset.part_quantity++
		let newQuantity = e[0].dataset.part_quantity
		// console.log(e[0].dataset.part_quantity)

		// Update the minus sign data as well
		let minusElement = document.querySelector(`.minus[data-part_id='${id}']`)
		minusElement.dataset.part_quantity++
		// Disable spans (both plus and minus)
		e.addClass('disabled')
		minusElement.classList.add('disabled')

		// Delete item from database, to then update it with the new quantity
		axios
			.post(
				`/wp-json/price-guide/v1/cart-delete?user_id=${userId}&part_id=${id}`
			)
			.then(function (response) {
				console.log(response)
				axios
					.post(
						`/wp-json/price-guide/v1/cart?user_id=${userId}&part_id=${id}&part_price=${price}&part_qty=${newQuantity}`
					)
					.then(function (response) {
						console.log(response)
						e.removeClass('disabled')
						minusElement.classList.remove('disabled')
					})
					.catch(function (error) {
						console.log(error)
					})
			})
			.catch(function (error) {
				console.log(error)
			})

		let counter = $(`input[data-part_id='${id}']`)
		console.log(counter.val())
		counter.val(parseInt(counter.val()) + 1)
	}
}

export default myCart
