import $ from 'jquery'

class run_carousel {
	//? 1. describe and create/initiate our object

	constructor() {
		this.fade_val = true
		this.autoPlay = true
		$('.your-class').slick({
			arrows: false,
			fade: this.fade_val,
			autoplay: this.autoPlay,
			autoplaySpeed: 6500,

			responsive: [
				{
					breakpoint: 576,
					settings: {
						dots: true,
					},
				},

				// You can unslick at a given breakpoint now by adding:
				// settings: "unslick"
				// instead of a settings object
			],
		})
		$('.your-timeline').slick({
			// arrows: true,
			slidesToShow: 5,
			slidesToScroll: 1,

			// autoplay: true,
			// autoplaySpeed: 3000,
			// centerMode: true,
			infinite: false,
			focusOnSelect: true,
			responsive: [
				{
					breakpoint: 1800,
					settings: {
						slidesToShow: 4,
						slidesToScroll: 4,
					},
				},
				{
					breakpoint: 1450,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 1,
					},
				},

				{
					breakpoint: 1050,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1,
					},
				},
				{
					breakpoint: 780,
					settings: {
						autoplay: false,
						slidesToShow: 1,
						slidesToScroll: 1,
					},
				},

				// You can unslick at a given breakpoint now by adding:
				// settings: "unslick"
				// instead of a settings object
			],
		})
		this.events()
	}

	//?events
	events() {
		let mainObject = this
		// link click triggers

		//? Timeline button click events
		$('.left-btn').on('click', function () {
			$('.your-timeline').slick('slickPrev')
		})
		$('.right-btn').on('click', function () {
			$('.your-timeline').slick('slickNext')
		})

		$('.quick-links').on('click', function () {
			event.preventDefault()
			console.log('next slide')
			// mainObject.autoPlay = false;
			$('.your-class').slick('slickPause')
			let slideNum = $(this).data('slide')
			$('.your-class').slick('slickGoTo', slideNum)
		})
		$('.slick-dots').on('click', function () {
			$('.autoplay').slick('slickPause')
		})
		// active state triggers
		$('.your-class').on(
			'beforeChange',
			function (event, slick, currentSlide, nextSlide) {
				/// remove class from all
				$('.quick-links').removeClass('live')
				/// add class to the active link
				$('.quick-links').each(function (i, obj) {
					if ($(obj).data('slide') === nextSlide) {
						$(obj).addClass('live')
					}
				})
			}
		)
	}

	//? 3. methods (functions)
	carousel_logic(element) {
		// console.log(element.data('slide'));
	}
}

export default run_carousel
