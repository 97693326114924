import $ from 'jquery'
// import lightbox from "lightbox";
class imgModal {
	//? 1. describe and create/initiate our object

	constructor() {
		this.events()
	}

	//? 2. events

	events() {
		// console.log("image modal!!");
		$('.image-link').click(function () {
			event.preventDefault()
		})
	}

	//? 3. methods (functions)
}

export default imgModal
