import $ from 'jquery'

class site {
	//? 1. describe and create/initiate our object

	constructor() {
		this.outPutDiv = $('#cal_price')
		this.adj_num = $('#adjustment_number').text()
		this.use_dynamic = $('#price-section').data('use_dynamic')
		this.videoLink = $('#playerID').attr('src')
		// percentage
		this.PT_perc = $('#price-section').data('pt_perc')
		this.PD_perc = $('#price-section').data('pd_perc')
		this.RH_perc = $('#price-section').data('rh_perc')
		// static values
		this.static_val_pt = $('#price-section').data('static_val_pt')
		this.static_val_pd = $('#price-section').data('static_val_pd')
		this.static_val_rh = $('#price-section').data('static_val_rh')
		// api key
		this.api_key = $('#price-section').data('not_user')
		//local price
		this.localPrice = $('#price-section').data('local_price')
		// avg price will need to cal
		this.avg_PGM_Price = 0

		//! log values for testing purposes
		// console.log('PT % : ', this.PT_perc);
		// console.log('PD % : ', this.PD_perc);
		// console.log('RH % : ', this.RH_perc);

		// console.log('PT Value : ', this.static_val_pt);
		// console.log('PD Value : ', this.static_val_pd);
		// console.log('RH Value : ', this.static_val_rh);
		this.events()
	}

	//? 2. events

	events() {
		let mainObject = this
		if ($('#cat-con-page')) {
			if (this.use_dynamic) {
				this.calc_avg_price()
				this.get_element_price()
			} else {
				this.default_to_local_price()
			}
		} else {
			console.log('not converter page')
		}

		$('#openVideo').click(function () {
			console.log(mainObject.videoLink)
			$('#playerID').attr('src', mainObject.videoLink)
			$('.video-popup ').removeClass('hide')
		})
		$('.close-btn').click(function () {
			$('.video-popup ').addClass('hide')
			// $('#playerID').get(0).stopVideo();
			$('#playerID').attr('src', '')
		})
		$('.video-popup').click(function () {
			$('.video-popup ').addClass('hide')
			// $('#playerID').get(0).stopVideo();
			$('#playerID').attr('src', '')
		})
		this.makeSameSize()
		// Anchor Scroll To
		if (document.querySelectorAll('a[href^="#"]')) {
			document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
				anchor.addEventListener('click', function (e) {
					e.preventDefault()

					document
						.querySelector(this.getAttribute('href'))
						.scrollIntoView({
							behavior: 'smooth',
						})
				})
			})
		}
	}

	//? 3. methods (functions)

	calc_avg_price() {
		/// equation
		let avg_PT = this.static_val_pt * (this.PT_perc / 100)
		let avg_PD = this.static_val_pd * (this.PD_perc / 100)
		let avg_RH = this.static_val_rh * (this.RH_perc / 100)

		this.avg_PGM_Price = avg_PT + avg_PD + avg_RH
		console.log('avg_PGM_Price', this.avg_PGM_Price)
	}

	get_element_price = () => {
		console.log('get_element_price is runing')
		let mainObject = this

		axios
			.get(
				'https://metals-api.com/api/latest?access_key=' + mainObject.api_key
			)
			.then(function (response) {
				console.log(response)
				mainObject.calc_price(
					response.data.rates.XPT,
					response.data.rates.XPD,
					response.data.rates.XRH
				)
			})
			.catch(function (error) {
				console.log(error)
				//todo if api doesn't work default default price in wp backend.
				mainObject.default_to_local_price()
			})
			.then(function () {
				// always executed
			})
	}

	makeSameSize() {
		let max_Height = 0
		// $('.same-size').count();
		$('.same-size').each(function (index) {
			console.log($(this).outerHeight())
			if ($(this).outerHeight() > max_Height) {
				max_Height = $(this).outerHeight()
			}
		})
		$('.same-size').height(max_Height)
	}

	calc_price(PT, PD, RH) {
		console.log('calc_price is runing')
		let platinum = 1 / PT
		let palladium = 1 / PD
		let rhodium = 1 / RH

		// Adjusted Value Price Sheet Calculation Basis
		let platinum_adj = platinum * 0.197
		let palladium_adj = palladium * 0.728
		let rhodium_adj = rhodium * 0.075

		let metal_sum = platinum_adj + palladium_adj + rhodium_adj
		let adj_calc = (metal_sum - this.avg_PGM_Price) / this.avg_PGM_Price
		adj_calc = adj_calc * 100
		let final_adj_perc = adj_calc + 100

		// result
		let price = this.adj_num * (final_adj_perc / 100)
		price = price.toFixed(0)

		console.log('price', price)
		this.outPutDiv.text('$' + price)
	}

	default_to_local_price() {
		this.outPutDiv.text('$' + this.localPrice)
	}
}

export default site
