import $ from 'jquery'

class myFavorites {
	//? 1. describe and create/initiate our object

	constructor() {
		this.search_button = $('.myFav')

		this.events()
	}

	//? 2. events

	events() {
		let myObj = this

		// $('.myFav').on('click', function () {
		// 	//Do something...
		// 	myObj.addFavorites();
		//  });

		$('#search-output').on('click', '.myFav', function () {
			//   console.log($(this).attr("data-partnumber"));
			// console.log("click");
			myObj.addFavorites($(this))
		})

		$('.fav-btn-remove').click(function () {
			event.preventDefault()
			myObj.removeFromFav($(this))
		})
		// this.search_button.on('click', this.addFavorites);
	}

	//? 3. methods (functions)

	addFavorites = (a) => {
		event.preventDefault()
		console.log('running addFavorites function')
		let partId = a.attr('data-partnumber')
		let partPrice = a.attr('data-price')
		let partMake = a.attr('data-make')
		let userID = $('#search-output').data('userid')
		console.log('this is my id : ', userID)
		let url = `/wp-json/price-guide/v1/favorites?user_id=${userID}&part_id=${partId}&part_price=${partPrice}&part_make=${partMake}`
		let myObj = this
		console.log('my url', url)
		console.log(a[0]['attributes'])
		console.log('NEW!')
		console.log(a.attr('isselected'))

		if (a[0]['attributes'][5].value == 'false') {
			a[0]['attributes'][5].value = 'true'
			a.html('<i class="fas fa-star"></i>')
			axios
				.post(url)
				.then(function (response) {
					console.log(response)
				})
				.catch(function (error) {
					console.log(error)
				})
		} else if (a[0]['attributes'][5].value == 'true') {
			a[0]['attributes'][5].value = 'false'
			a.html('<i class="far fa-star"></i>')
			axios
				.post(
					`/wp-json/price-guide/v1/favorites-delete?user_id=${userID}&part_id=${partId}&part_price=${partPrice}&part_make=${partMake}`
				)
				.then(function (response) {
					console.log(response)
				})
				.catch(function (error) {
					console.log(error)
				})
		}
	}

	removeFromFav = (a) => {
		console.log(a.data('partid'))
		console.log(a.data('userid'))
		let partId = a.data('partid')
		let userId = a.data('userid')
		$('.history-results ').find(`[data-deleteCode='${partId}']`).remove()

		axios
			.post(
				`https://noble6.com/wp-json/price-guide/v1/favorites-delete?user_id=${userId}&part_id=${partId}`
			)
			.then(function (response) {
				console.log(response)
			})
			.catch(function (error) {
				console.log(error)
			})
	}
}

export default myFavorites
