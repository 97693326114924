// ONLY FUTURE Dates

document.addEventListener('DOMContentLoaded', function (event) {
	// make shipping form email ready only
	if (document.querySelector('.gf_readonly input')) {
		document.querySelectorAll('.gf_readonly input').forEach(function (item) {
			item.setAttribute('readonly', 'readonly')
		})
	}

	//....
	if (document.getElementById('input_1_3')) {
		const catalyticConvertersTextURL =
			document.getElementById('input_1_3').value

		const convertersOl = `
    <table style="width: 100%; max-width:350px;">
    <tr>
    <th style="padding: 5px 25px 5px 0;" align="left">Part Number</th>
    <th style="padding: 5px 25px 5px 0;" align="left">Quantity</th>
    <th style="padding: 5px 25px 5px 0;" align="left">Estimated Price</th>
    </tr>
    ${catalyticConvertersTextURL}
    </table>
    `
		document.getElementById('input_1_3').value = convertersOl

		// HIDE URL PARAMETERS FROM USERS
		history.pushState(null, '', location.href.split('?')[0])

		// document
		// 	.getElementById('input_1_3_ifr')
		// 	.addEventListener('load', function () {
		// 		console.log('The `iframe` has loaded!')
		// 	})
	}

	var url = window.location.href
	url = url.split('?')[0]
})
