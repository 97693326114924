import $ from 'jquery'

class Resources {
	//? 1. describe and create/initiate our object

	constructor() {
		if (this.getUrlParameter('cat')) {
			this.cats = this.getUrlParameter('cat')
				.split(',')
				.map(function (item) {
					return parseInt(item, 10)
				})
		} else {
			this.cats = []
		}
		if (this.getUrlParameter('type')) {
			this.types = this.getUrlParameter('type').split(',')
		} else {
			this.types = []
		}

		this.url = '/blog/?cat='
		this.myTimer = null
		this.breadcrumb = ''
		this.events()
	}

	//? 2. events

	events() {
		let mainObject = this
		this.ActivateChecks()
		$('.blog-input').on('change', function () {
			clearTimeout(mainObject.myTimer)
			mainObject.myTimer = setTimeout(function () {
				location.href = mainObject.url
				// console.log('myurl', mainObject.url);
			}, 1000)
			$('.loader-parent').removeClass('hide')
			$('.blog-list').addClass('hide')
			let id = $(this).data('id')

			if (!this.checked) {
				mainObject.buildURL(id, true, true)
			} else {
				mainObject.buildURL(id, false, true)
			}
		})

		$('#search-btn').click(function () {
			console.log($('#search-form').val())
		})

		$('input').on('keydown', function search(e) {
			if (e.keyCode == 13) {
				// console.log($('#search-form').val());
				if ($('#search-form').val()) {
					location.href = '/blog/?search=' + $('#search-form').val()
				}
			}
		})

		$('.blog-input-resource').on('change', function () {
			clearTimeout(mainObject.myTimer)
			mainObject.myTimer = setTimeout(function () {
				location.href = mainObject.url
			}, 1000)
			$('.loader-parent').removeClass('hide')
			$('.blog-list').addClass('hide')
			let id = $(this).data('id')

			if (!this.checked) {
				mainObject.buildURL(id, true, false)
			} else {
				mainObject.buildURL(id, false, false)
			}
		})
	}

	//? 3. methods (functions)
	ActivateChecks() {
		let searchParams = new URLSearchParams(window.location.search)

		if (searchParams.has('search')) {
			let param = searchParams.get('search')
			$('.bread-cat').text('Searching for: ' + param)
		}

		let mainObject = this

		$('.blog-input').each(function () {
			// console.log('main id', $(this).data('id'));

			let arry = mainObject.cats
			let objectID = $(this).data('id')
			let slug = $(this).data('slug')

			var i
			for (i = 0; i < arry.length; ++i) {
				if (objectID === arry[i]) {
					$(this).prop('checked', true)
					mainObject.addToBreadcrumb(slug, true)
				}
			}
		})

		$('.blog-input-resource').each(function () {
			// console.log('main slug', $(this).data('id'));

			let arry = mainObject.types
			let objectID = $(this).data('id')
			let slug = $(this).data('slug')

			var i
			// console.log(arry);
			for (i = 0; i < arry.length; ++i) {
				// console.log(arry[i]);
				if (objectID === arry[i]) {
					$(this).prop('checked', true)
					mainObject.addToBreadcrumb(slug, true)
				}
			}
		})
	}
	buildURL(newID, e, isID) {
		let mainObject = this
		// console.log(this.getUrlParameter('cat'));
		// 'https://noble6.com/blog/?cat=3,5,10'

		if (isID) {
			if (!e) {
				this.cats.push(newID)
			} else {
				Array.prototype.remove = function (v) {
					this.splice(
						this.indexOf(v) == -1 ? this.length : this.indexOf(v),
						1
					)
				}
				this.cats.remove(newID)
			}
			// console.log('this.cats', this.cats);
		} else {
			if (!e) {
				this.types.push(newID)
			} else {
				Array.prototype.remove = function (v) {
					this.splice(
						this.indexOf(v) == -1 ? this.length : this.indexOf(v),
						1
					)
				}
				this.types.remove(newID)
			}
		}

		if (this.cats.length > 0 || this.types.length > 0) {
			mainObject.url = '/blog/?'
		} else {
			mainObject.url = '/blog/'
		}
		if (this.cats.length > 0) {
			mainObject.url += 'cat=' + mainObject.cats
			// console.log(mainObject.cats);
		}
		if (this.types.length > 0) {
			mainObject.url += '&type=' + this.types
		}
	}

	addToBreadcrumb(cat) {
		this.breadcrumb += cat + ', '
		let breadConsole = this.breadcrumb
		console.log(breadConsole)
		let str = this.breadcrumb.replace(/,\s*$/, '')
		$('.bread-cat').text(str)
		// console.log(this.breadcrumb);
	}
	getUrlParameter(sParam) {
		var sPageURL = window.location.search.substring(1),
			sURLVariables = sPageURL.split('&'),
			sParameterName,
			i

		for (i = 0; i < sURLVariables.length; i++) {
			sParameterName = sURLVariables[i].split('=')

			if (sParameterName[0] === sParam) {
				return typeof sParameterName[1] === undefined
					? true
					: decodeURIComponent(sParameterName[1])
			}
		}
		return false
	}
}

export default Resources
