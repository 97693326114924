import $ from 'jquery'

class mySearch {
	//? 1. describe and create/initiate our object

	constructor() {
		this.search_input = $('#search-input')
		this.search_button = $('#search-btn')
		this.search_output = $('#search-output')
		this.submit_search_btn = $('#submit-search-btn')
		this.userID = $('#search-output').data('userid')
		this.myCountLimit = $('#myCount').data('limit')
		this.events()
	}

	//? 2. events

	events() {
		$('#rcp_submit').attr('value', 'SUBMIT')
		let myObj = this
		const queryString = window.location.search
		// console.log(queryString)
		const urlParams = new URLSearchParams(queryString)
		const historySearch = urlParams.get('search')
		myObj.loadModal()
		if (historySearch) {
			myObj.search_input.val(historySearch)
			console.log('search history', historySearch)
			myObj.getSearchResults
			myObj.getHistroyResults()
			$('.succesfull-alert').addClass('hide')
		}

		this.search_button.on('click', this.getSearchResults)
		this.submit_search_btn.on('click', this.getSearchResults)
		this.search_input.on('keydown', function search(e) {
			if (e.keyCode == 13) {
				myObj.getSearchResults()
				console.log(e.keyCode)
			}
		})
	}

	//? 3. methods (functions)
	generateHTML(data) {
		let myObject = this
		// console.log('gen data', $('#search-input').data('userrole'))
		let UserRole = $('#search-input').data('userrole')
		let cart_btn = ''
		let myCount = 1
		if (data) {
			console.log(data)
			this.search_output.html(
				data.map(
					(item, index) =>
						`<div class="listItem">
			<div class="listItem-child">
				<div class="row justify-content-center pos-relative ">
				<div id="fav" 
				data-partNumber="${item.part_number}" 
				data-price="${item.price}" 
				data-make="${item.make}" 
				class="fav-container is_fav myFav ${
					UserRole === 'gold' ||
					UserRole === 'administrator' ||
					UserRole == 'vip'
						? ''
						: 'hide'
				}" data-isSelected="${item.isFav ? 'true' : 'false'}">
        ${
				item.isFav
					? '<i class="fas fa-star"></i>'
					: '<i class="far fa-star"></i>'
			}
			



			</div>
					<div class="col-xl-3 col-lg-3 col-md-3 col-12 img-col ">

        
						<div class="imageholder">
            <a data-partnum="${
					item.part_number
				}" href="/wp-content/uploads/2022/02/istockphoto-62735751cat-placeholder6-612x612-1.png" data-group="${index}" class="galleryItem test main-img">
							<img src="/wp-content/uploads/2022/02/istockphoto-62735751cat-placeholder6-612x612-1.png" alt="">
              </a> 
						</div>
            <div href="/wp-content/uploads/2022/02/istockphoto-62735751cat-placeholder6-612x612-1.png" data-partnum="${
					item.part_number
				}"  data-group="${index}" class="galleryItem test123 backup-img hide "></div>

					</div>
					<div class="col-xl-9 col-lg-9 col-md-9 col-12">
						<div class="row cont-row justify-content-center">
	
							<div class="col-xl-4 col-lg-5 col-md-12 col-10">
								<div class="item col-12">
									<h3>
										Part Number:
									</h3>
									<h4>
										${item.part_number}</h4>
								</div>
								<div class="item col-12">
									<h3>
										Price:
									</h3>
									<h1>$<span>${item.price}</span></h1>
								</div>
	
							</div>
							<div class="col-xl-4 col-lg-5 col-md-12 col-10 add-to-cart-col">
							${
								item.make
									? `<div class="item col-12">
									<h3>
                  Manufacturer:
									</h3>
									<h4>${item.make}</h4>
								</div>`
									: `<div class="item col-12">
								<h3>
								&nbsp;
								</h3>
								<h4> &nbsp; </h4>
							</div>`
							}	
							
	
								<div class="item col-12">
								
              ${
						true
							? `<div>
              <a href="#" class="secondary_btn adjust-btn pg-contact-btn add-to-cart ${
						item.inCart ? 'bisabled-btn' : ''
					}" data-partNumber="${item.part_number}" data-price="${
									item.price
							  }"  >
                ${
							item.inCart
								? 'Item added'
								: 'Add to Cart <i class="fas fa-arrow-right"></i>'
						}
                
              </a>
            </div>`
							: ''
					}
								
								</div>
							</div>
	
	
						</div>
					</div>
				</div>
			</div>
		</div>`
				)
			)

			if (
				UserRole == 'administrator' ||
				UserRole == 'gold' ||
				UserRole == 'vip'
			) {
				// Uncomment below to pull data from image folder - see logic for specified path and naming convention for the images
				myObject.loadImage()
			}
			// Uncomment for image
			myObject.loadModal()
		} else {
			console.log('no data')

			$('#search-output').html('')
			$('#errorMessageOutput').removeClass('hide')
		}
	}

	updateToolbar(num) {
		let myObj = this
		$('.search-titlebar').removeClass('hide')
		$('.code').html(myObj.search_input.val())
	}

	updateCount(num) {
		// num variable starts from 3, then goes down by 1
		let myObj = this
		$('#myCount').html(myObj.myCountLimit - num)
		if (myObj.myCountLimit <= num) {
			$('#searchBar').addClass('hide')
			$('#limitReached').removeClass('hide')
		}
	}

	getSearchResults = () => {
		if (this.search_input.val()) {
			$('.succesfull-alert').addClass('hide')
			$('#errorMessageOutput').addClass('hide')
			event.preventDefault()
			let mainObject = this
			// console.log('getSearchResults', mainObject.search_input.val())
			// console.log('mainObject.userID', mainObject.userID)
			axios
				.post(
					'/wp-json/price-guide/v1/search?user_id=' +
						mainObject.userID +
						'&part_id=' +
						mainObject.search_input.val()
				)
				.then(function (response) {
					console.log(response)
					mainObject.generateHTML(response.data.result)
					mainObject.updateCount(response.data.count)
					mainObject.updateToolbar()
				})
				.catch(function (error) {
					console.log(error)
				})
		}
	}

	getHistroyResults = (event) => {
		let mainObject = this
		console.log('getSearchResults', mainObject.search_input.val())
		axios
			.post(
				'/wp-json/price-guide/v1/search?user_id=' +
					mainObject.userID +
					'&part_id=' +
					mainObject.search_input.val()
			)
			.then(function (response) {
				console.log(response)
				mainObject.generateHTML(response.data.result)
				mainObject.updateCount(response.data.count)
				mainObject.updateToolbar()
			})
			.catch(function (error) {
				console.log(error)
			})
	}

	loadModal = () => {
		var groups = {}

		$('.galleryItem').each(function () {
			// console.log('asdgfasdg')
			var id = parseInt($(this).attr('data-group'), 10)

			if (!groups[id]) {
				groups[id] = []
			}

			groups[id].push(this)
		})

		$.each(groups, function () {
			$(this).magnificPopup({
				type: 'image',
				mainClass: 'converter-modal',
				closeOnContentClick: true,
				showCloseBtn: true,
				closeBtnInside: true,
				gallery: { enabled: true },
			})
		})
	}

	checkImage(url, myimg) {
		var request = new XMLHttpRequest()
		request.open('GET', url, true)

		request.send()
		request.onload = function () {
			status = request.status
			if (request.status == 200) {
				//if(statusText == OK)
				console.log('image exists')
				myimg.attr('href', url)
				myimg.children('img').attr('src', url)
				console.log(myimg)
				return true
			} else {
				console.log("image doesn't exist")
				return false
			}
		}
	}

	loadImage() {
		let myObj = this
		$('.main-img').each(function (i, obj) {
			//test
			let imgContainer = $(this)
			// console.log('hello worldsdf', $(this).data('partnum'))

			myObj.checkImage(
				'https://noble6.com/wp-content/uploads/n6-images/' +
					$(this).data('partnum') +
					'-front.jpg',
				imgContainer
			)
		})

		$('.backup-img').each(function (i, obj) {
			//test
			let imgContainer = $(this)
			// console.log('hello worldsdf', $(this).data('partnum'))

			myObj.checkImage(
				'https://noble6.com/wp-content/uploads/n6-images/' +
					$(this).data('partnum') +
					'-back.jpg',
				imgContainer
			)
		})
	}
}

export default mySearch
