import $ from 'jquery'

class main_Nav {
	//? 1. describe and create/initiate our object
	constructor() {
		this.navbar = $('#main-navbar')
		this.logo = $('.nav-logo')
		this.dark_logo = '/wp-content/uploads/2022/03/noble-6-logo-.svg'
		this.light_logo = '/wp-content/uploads/2022/03/noble-6-logo-light.svg'
		this.navHover()
		this.events()
	}
	//? 2. events
	events() {
		// const navbar = document.querySelector('#nav');
		const main_object = this
		// console.log(window.scrollY);
		if (window.scrollY) {
			this.on_load_color_shift()
		}
		window.addEventListener('scroll', function (e) {
			main_object.nav_color_shift()
		})
		// console.log('hell world', $(window).width());

		main_object.ajust_box_height()
		$(window).resize(function () {
			// console.log('js')
			main_object.ajust_box_height()
		})
	}
	//? 3. methods (functions)
	nav_color_shift() {
		// console.log('scrolling');

		const lastPosition = window.scrollY
		if (lastPosition > 50) {
			this.navbar.addClass('nav-light')

			this.logo.attr('src', this.dark_logo)
		} else if (this.navbar.hasClass('nav-light')) {
			this.navbar.removeClass('nav-light')
			if (this.logo.data('color') === 'dark') {
				this.logo.attr('src', this.dark_logo)
			} else {
				this.logo.attr('src', this.light_logo)
			}
		} else {
			this.navbar.removeClass('nav-light')
			if (this.logo.data('color') === 'dark') {
				this.logo.attr('src', this.dark_logo)
			} else {
				this.logo.attr('src', this.light_logo)
			}
		}
	}
	on_load_color_shift() {
		this.navbar.addClass('nav-light')
		this.logo.attr('src', this.dark_logo)
	}
	ajust_box_height() {
		$('.adapt-height').each(function () {
			$(this).height($(this).width())
		})

		$('.square').height($('.square').width())
	}
	navHover() {
		const $dropdown = $('.dropdown')
		const $dropdownToggle = $('.dropdown-toggle')
		const $dropdownMenu = $('.dropdown-menu')
		const showClass = 'show'

		$(window).on('load resize', function () {
			if (this.matchMedia('(min-width: 768px)').matches) {
				$dropdown.hover(
					function () {
						const $this = $(this)
						$this.addClass(showClass)
						$this.find($dropdownToggle).attr('aria-expanded', 'true')
						$this.find($dropdownMenu).addClass(showClass)
					},
					function () {
						const $this = $(this)

						$this.removeClass(showClass)
						$this.find($dropdownToggle).attr('aria-expanded', 'false')
						$this.find($dropdownMenu).removeClass(showClass)
					}
				)
			} else {
				$dropdown.off('mouseenter mouseleave')
			}
		})
	}
}

export default main_Nav
